import useDevice from './useDevice'
import { useMemo } from 'react'

const useExternalLink = () => {
  const { isReactNative, isNativeApp } = useDevice()

  const externalProtocol = useMemo(() => {
    return isNativeApp && !isReactNative ? 'external:' : ''
  }, [isNativeApp, isReactNative])

  return externalProtocol
}

export default useExternalLink
