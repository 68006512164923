/* eslint-disable @typescript-eslint/no-explicit-any */

import { get, post } from 'config/apiActions'
import { isUndefined } from 'lodash'

import type {
  ConsumerLoginRequest,
  ConsumerLoginResponse,
  ConsumerVerifyRequest,
  ConsumerVerifyResponse,
  GetUserPointsLedgerRequest,
  OrderRepeatRequest,
  OrderRepeatResponse,
  PaymentAddResponse,
  PaymentAddBankRequest,
  PaymentAddBankResponse,
  ShareCodeRequest,
  ShareCodeResponse,
  StoreLocationsRequest,
  StoreLocationsResponse,
  StoreMenuRequest,
  StoreMenuResponse,
  StoresSearchRequest,
  StoresSearchResponse,
  StoreStatusRequest,
  StoreStatusResponse,
  StoresListRequest,
  StoresListResponse,
  StoresMapRequest,
  StoresMapResponse,
  UserMyJoeResponse,
  GetUserPaymentMethodsResponse,
  ExternalPaymentMethod,
  GetUserPaymentMethodsRequest,
  SaveNewPaymentMethodRequest,
  SaveNewPaymentMethodResponse,
  SetDefaultPaymentMethodRequest,
  GetUserLoyaltyCardsResponse,
  ArgsMap,
  ApiStatus,
  AsyncReturnType,
} from 'types/api'

import type { UserFeedback, UserFeedbackRequest } from 'types/api/feedback'

import type {
  CreateOrderRequest,
  CreateOrderResponse,
  GetOrderDetailsRequest,
  GetOrderDetailsResponse,
  GetOrderPricingRequest,
  GetOrderPricingResponse,
  GetUserCurrentOrderDetailsResponse,
  GetOrderStatusRequest,
  GetOrderStatusResponse,
  Order,
  OrderFeedbackRequest,
  OrderFeedbackResponse,
  UpdateOrderArrivalRequest,
  UpdateOrderArrivalResponse,
  GetUserOrderListResponse,
  GetUserActiveOrdersRequest,
  GetUserActiveOrdersResponse,
  GetUserCurrentOrderDetailsRequest,
} from 'types/api/order'

import type {
  GetStoreInfoByIdRequest,
  StoreInfo,
  GetStoreItemDetailsByIdRequest,
  StoreItem,
  GetStoreItemModificationByIdRequest,
  ItemModification,
  StoreDetailsOnMap,
  GetStoreDetailsOnMapRequest,
  GetStoreItemSizesByIdRequest,
  GetStoreUpsellItemsRequest,
  StoreUpsellItem,
  ItemSize,
  GetStorePickUpOptionsRequest,
  GetStorePickUpOptionsResponse,
  GetStoreItemModificationsRequest,
  GetStoreItemModificationsResponse,
  GetStoreItemModificationQuantitiesRequest,
  GetStoreItemModificationQuantitiesResponse,
  StoreTopOrderedResponse,
} from 'types/api/store'

import type {
  UpdateUserNameRequest,
  UpdateUserNameResponse,
  UserProfileBasicRequest,
  UserSetPhotoRequest,
  UserSetPhotoResponse,
  GetUserRewardDetailRequest,
  UserRewardDetail,
  UserProfileBasic,
  UserWalletInfo,
  JoebucksAddBalanceRequest,
  GetUserWalletAddFundInfoResponse,
  GetUserWalletAddFundInfoRequest,
  JoebucksAddBalanceResponse,
  GetJoeOfferDetailsRequest,
  Offer,
  GetJoeOffers,
  GetUserPointsLedgerResponse,
  GetUserTransactionHistoryResponse,
  UpdateAutoReloadRequest,
  UserSettingsResponse,
  UsualItem,
  FavoriteStore,
  // OfferDetails,
  // GetJoeOfferDetailsRequest,
  // Offer,
} from 'types/api/user'

import {
  GetLandingPageCodeRequest,
  GetLandingPageCodeResponse,
  GetLandingPageForInactiveUserRequest,
  GetLandingPageForInactiveUserResponse,
} from 'types/api/lp'
import {
  DeleteStripePaymentMethodRequest,
  DeleteStripePaymentMethodResponse,
  GetStripeSetupIntentRequest,
  GetStripeSetupIntentResponse,
} from 'types/api/stripe'
import {
  RedeemGiftCardCodeRequest,
  RedeemGiftCardCodeResponse,
  PurchaseGiftCardRequest,
  PurchaseGiftCardResponse,
  PurchasedCompanyGiftCards,
  GetCompanyGiftCardByIdRequest,
  GetCompanyGiftCardByIdResponse,
  PurchaseCompanyGiftCardData,
  PurchaseCompanyGiftCardResponse,
  ActivateCompanyGiftCardRequest,
  CompanyGiftCardPurchase,
  GetCompanyGiftCardDealsResponse,
  AddPhysicalGiftCardData,
  AddPhysicalGiftCardResponse,
  GetCompanyGiftCardUploadsRequest,
  PurchasedJoeGiftCard,
} from 'types/api/giftcard'
import { CartData, GetCartOptionsResponse } from 'types/api/cart'
import {
  GetFailedPaymentResponse,
  UpdateFailedPaymentsRequest,
} from 'types/api/failedPayment'
import { AddUsualItemRequest, RemoveUsualItemRequest } from 'types/api/item'
import { GetUserInboxRequest, GetUserInboxResponse } from 'types/api/inbox'

export class DefaultApi {
  public async consumerLogin(args: ConsumerLoginRequest, cookie?: string) {
    return await post<ConsumerLoginResponse>('/authentication/login', args, {
      cookie,
    })
  }

  public async consumerVerify(args: ConsumerVerifyRequest, cookie?: string) {
    return await post<ConsumerVerifyResponse>('/authentication/verify', args, {
      cookie,
    })
  }

  public async userMyJoe(cookie?: string) {
    return get<UserMyJoeResponse>('/user/my-joe', { cookie })
  }

  public async createOrder(args: CreateOrderRequest, cookie?: string) {
    return await post<CreateOrderResponse>('/order/create', args, { cookie })
  }

  public async getPaymentAddBank(args: PaymentAddBankRequest, cookie?: string) {
    return await get<PaymentAddBankResponse>(
      `/payment/add-bank/?userId=${args?.userId}`,
      { cookie }
    )
  }

  public async createUserSetupPaymentIntent(cookie?: string) {
    return await post<PaymentAddResponse>('/payment/add-card', undefined, {
      cookie,
    })
  }

  public async saveNewPaymentMethod(
    args: SaveNewPaymentMethodRequest,
    cookie?: string
  ) {
    return await post<SaveNewPaymentMethodResponse>('/payment/save', args, {
      cookie,
    })
  }

  public async deleteStripePaymentMethod(
    args: DeleteStripePaymentMethodRequest
  ) {
    return await post<DeleteStripePaymentMethodResponse>(
      '/payment/delete',
      args
    )
  }

  public async setDefaultPaymentMethod(args: SetDefaultPaymentMethodRequest) {
    return await post('/payment/set-default', args)
  }

  public async unsetDefaultPaymentMethod(cookie?: string) {
    return await post('/payment/unset-default', {}, { cookie })
  }

  // FROM ONE SIGNAL
  public async getJoeOffers(args: GetJoeOffers, cookie?: string) {
    const { overrideDay } = args
    return await get<Offer[]>('/user/offers', {
      cookie,
      params: { overrideDay },
    })
  }

  public async getJoeOfferDetails(
    args: GetJoeOfferDetailsRequest,
    cookie?: string
  ) {
    return await get<Offer>('/user/offer', {
      cookie,
      params: { offerId: args.offerId },
    })
  }

  public async getCartOptionByStoreId(
    args: { storeId: string },
    cookie?: string
  ) {
    return await get<GetCartOptionsResponse>(
      `/store/cart-options/?storeId=${args.storeId}`,
      { cookie }
    )
  }

  public async getLandingPageCode(
    args: GetLandingPageCodeRequest,
    cookie?: string
  ) {
    return await get<GetLandingPageCodeResponse>(
      `/landing-page/?urlSlug=${args.slug}`,
      { cookie }
    )
  }

  public async getLandingPageForInactiveUser(
    args: GetLandingPageForInactiveUserRequest,
    cookie?: string
  ) {
    return get<GetLandingPageForInactiveUserResponse>(
      `/landing-page/inactive-users/?shareCode=${args.shareCode}`,
      { cookie }
    )
  }

  public async getStoresMapPins(args: StoresMapRequest, cookie?: string) {
    return await get<StoresMapResponse>(
      `/stores/map?lon=${args.lon}&lat=${args.lat}`,
      { cookie }
    )
  }

  public async getStoreUsualItems(args: { storeId: string }, cookie?: string) {
    return await get<{ items: UsualItem[], store: FavoriteStore }>(
      `/store/usual-items?storeId=${args.storeId}`,
      { cookie }
    )
  }

  public async getStoreTopOrderedItems(
    args: { storeId: string },
    cookie?: string
  ) {
    return await get<StoreTopOrderedResponse>(
      `/store/top-ordered?storeId=${args.storeId}`,
      { cookie }
    )
  }

  public async getStoresByCompanyId(
    args: { companyId: string },
    cookie?: string
  ) {
    return await get<StoresListResponse>('/company/stores', {
      cookie,
      params: { companyId: args.companyId },
    })
  }

  public async getStoresSearchResults(
    args: StoresSearchRequest,
    cookie?: string
  ) {
    return await get<StoresSearchResponse>(
      `/stores/search?curb=${args.curb}&lat=${args.lat}&lon=${args.lon}&term=${args.term}`,
      { cookie }
    )
  }

  public async getOrderDetails(args: GetOrderDetailsRequest, cookie?: string) {
    return await get<GetOrderDetailsResponse>(
      `/order/complete?orderId=${args.orderId}`,
      { cookie }
    )
  }

  public async getOrderRepeat(args: OrderRepeatRequest, cookie?: string) {
    return get<OrderRepeatResponse>(`/order/repeat?orderId=${args.orderId}`, {
      cookie,
    })
  }

  public async getOrderStatus(args: GetOrderStatusRequest, cookie?: string) {
    return get<GetOrderStatusResponse>(
      `/order/status?orderId=${args.orderId}`,
      { cookie }
    )
  }

  public async getOrderPricing(args: GetOrderPricingRequest, cookie?: string) {
    return await post<GetOrderPricingResponse>('/order/pricing', args, {
      cookie,
    })
  }

  public async getStoreLocations(args: StoreLocationsRequest, cookie?: string) {
    return await get<StoreLocationsResponse>(
      `/store/locations?companyName=${args?.companyName}`,
      { cookie }
    )
  }

  public async getStoreStatus(args: StoreStatusRequest, cookie?: string) {
    return await get<StoreStatusResponse>(
      `/store/status?storeId=${args?.storeId}`,
      { cookie }
    )
  }

  public async getStoresByDistance(args: StoresListRequest, cookie?: string) {
    const headers = { 'x-forwarded-for': args.ip ?? '' }
    return await get<StoresListResponse>(
      `/stores/list${
        args.lat && args.lon ? `?lat=${args.lat}&lon=${args.lon}` : ''
      }`,
      { cookie, headers }
    )
  }

  public async getStoreDetailsOnMap(
    args: GetStoreDetailsOnMapRequest,
    cookie?: string
  ) {
    return await get<StoreDetailsOnMap>(
      `/store/card?storeId=${args.storeId}&lon=${args.lon}&lat=${args.lat}`,
      { cookie }
    )
  }

  public async getStoreInfoById(
    args: GetStoreInfoByIdRequest,
    cookie?: string
  ) {
    return await get<StoreInfo>(`/store/info?storeId=${args.storeId}`, {
      cookie,
    })
  }

  public async getStoreDetailsById(args: StoreMenuRequest, cookie?: string) {
    return await get<StoreMenuResponse>(`/store/menu?storeId=${args.storeId}`, {
      cookie,
    })
  }

  public async getStoreItemDetailsById(
    args: GetStoreItemDetailsByIdRequest,
    cookie?: string
  ) {
    return await get<StoreItem>(
      `/item/details?itemId=${args.itemId}&storeId=${args.storeId}`,
      { cookie }
    )
  }

  public async getStoreItemModifications(
    args: GetStoreItemModificationsRequest,
    cookie?: string
  ) {
    return await get<GetStoreItemModificationsResponse>(
      `/item/modifications?itemId=${args.itemId}&storeId=${args.storeId}`,
      { cookie }
    )
  }

  public async getStoreItemModificationDetailsById(
    args: GetStoreItemModificationByIdRequest,
    cookie?: string
  ) {
    return await get<ItemModification>(
      `/item/modification?sizeId=${args.sizeId}&modificationId=${args.modificationId}&storeId=${args.storeId}`,
      { cookie }
    )
  }

  public async getStoreItemModificationQuantities(
    args: GetStoreItemModificationQuantitiesRequest,
    cookie?: string
  ) {
    return await get<GetStoreItemModificationQuantitiesResponse>(
      `/item/modification-choice?modificationId=${args.modificationId}&choiceId=${args.choiceId}&sizeId=${args.sizeId}&storeId=${args.storeId}`,
      { cookie }
    )
  }

  public async getStoreItemSizeById(
    args: GetStoreItemSizesByIdRequest,
    cookie?: string
  ) {
    return await get<ItemSize[]>(
      `/item/sizes?itemId=${args.itemId}&storeId=${args.storeId}`,
      { cookie }
    )
  }

  public async getStorePickUpOptions(
    args: GetStorePickUpOptionsRequest,
    cookie?: string
  ) {
    return await get<GetStorePickUpOptionsResponse>(
      `/store/pickup-options?storeId=${args.storeId}`,
      { cookie }
    )
  }

  public async getStoreUpsellItems(
    args: GetStoreUpsellItemsRequest,
    cookie?: string
  ) {
    return await get<StoreUpsellItem[]>(
      `/store/upsell-items?storeId=${args.storeId}`,
      { cookie }
    )
  }

  public async getUserActiveOrderDetail(
    args: GetUserCurrentOrderDetailsRequest,
    cookie?: string
  ) {
    return get<GetUserCurrentOrderDetailsResponse | null>('/order/active', {
      cookie,
      params: {
        orderId: args?.orderId,
        overrideDay: args.overrideDay,
      },
    })
  }

  public async getUserActiveOrders(
    args: GetUserActiveOrdersRequest,
    cookie?: string
  ) {
    return get<GetUserActiveOrdersResponse>('/order/active-orders', {
      cookie,
      params: {
        userId: args?.userId,
      },
    })
  }

  public async getUserOrderList(cookie?: string) {
    return await get<GetUserOrderListResponse>('/user/orders', { cookie })
  }

  public async getUserPaymentMethods(
    {
      storeId,
      excludeJoebucks,
      type,
      includeDefaultPaymentMethodId,
    }: GetUserPaymentMethodsRequest,
    cookie?: string
  ) {
    const searchParams = new URLSearchParams()

    if (storeId) searchParams.set('storeId', storeId)
    if (excludeJoebucks) searchParams.set('excludeJoebucks', 'true')
    if (type) searchParams.set('type', type)
    if (includeDefaultPaymentMethodId)
      searchParams.set('includeDefaultPaymentMethodId', 'true')

    return get<GetUserPaymentMethodsResponse>(
      `/payments/list?${searchParams.toString()}`,
      { cookie }
    )
  }

  public async getUserLoyaltyCards(cookie?: string) {
    return get<GetUserLoyaltyCardsResponse>('/payments/loyalty-cards', {
      cookie,
    })
  }

  public async getUserPaymentMethod(
    { paymentMethodId }: { paymentMethodId: string },
    cookie?: string
  ) {
    return get<{ paymentMethod?: ExternalPaymentMethod }>(
      `/payment?paymentMethodId=${paymentMethodId}`,
      { cookie }
    )
  }

  public async getUserPointsLedger(
    args: GetUserPointsLedgerRequest,
    cookie?: string
  ) {
    return get<GetUserPointsLedgerResponse>('/user/rewards', {
      cookie,
      params: args,
    })
  }

  public async getUserRewardDetail(
    args: GetUserRewardDetailRequest,
    cookie?: string
  ) {
    return await get<UserRewardDetail>('/user/points', { cookie })
  }

  public async getUserWalletInfo(cookie?: string) {
    return await get<UserWalletInfo>('/user/wallet', { cookie })
  }

  public async getUserWalletAddFundInfo(
    args: GetUserWalletAddFundInfoRequest,
    cookie?: string
  ) {
    return await get<GetUserWalletAddFundInfoResponse>(
      '/user/wallet/add-funds',
      { cookie }
    )
  }

  // TODO: Are we using this, or can we delete it?
  public async getStripeSetupIntent(args: GetStripeSetupIntentRequest) {
    return await post<GetStripeSetupIntentResponse>(
      '/api/stripe/card/secret',
      args,
      { useNextApi: true }
    )
  }

  public async joebucksAddBalance(
    args: JoebucksAddBalanceRequest,
    cookie?: string
  ) {
    return await post<JoebucksAddBalanceResponse>('/payment/upload', args, {
      cookie,
    })
  }

  public async notifyAddingBankAccount(args: unknown, cookie?: string) {
    return await post('/user/bank-account', {}, { cookie })
  }

  public async orderFeedback(args: OrderFeedbackRequest, cookie?: string) {
    return await post<OrderFeedbackResponse>(
      '/analytics/order-feedback',
      args,
      { cookie }
    )
  }

  public async purchaseGiftCard(
    args: PurchaseGiftCardRequest,
    cookie?: string
  ) {
    return await post<PurchaseGiftCardResponse>('/gift-card', args, { cookie })
  }

  public async addPhysicalGiftCard(
    args: AddPhysicalGiftCardData,
    cookie?: string
  ) {
    return await post<AddPhysicalGiftCardResponse>(
      '/gift-card/link-gift-card',
      args,
      {
        cookie,
      }
    )
  }

  public async redeemGiftCard(
    args: RedeemGiftCardCodeRequest,
    cookie?: string
  ) {
    return await post<RedeemGiftCardCodeResponse>('/user/gift-card', args, {
      cookie,
    })
  }

  public async updateUserActiveOrderArrivalStatus(
    args: UpdateOrderArrivalRequest,
    cookie?: string
  ) {
    return await post<UpdateOrderArrivalResponse>('/order/user-arrived', args, {
      cookie,
    })
  }

  public async userFeedback(data: UserFeedbackRequest, cookie?: string) {
    return await post<UserFeedback>('/analytics/user-feedback', data, {
      cookie,
    })
  }

  public async userGetByShareCode(args: ShareCodeRequest, cookie?: string) {
    return await get<ShareCodeResponse | null>(
      `/share-code?shareCode=${args.shareCode}`,
      { cookie }
    )
  }

  public async userGetProfileBasic(
    args: UserProfileBasicRequest,
    cookie?: string
  ) {
    return await get<UserProfileBasic>('/user/profile', { cookie })
  }

  public async userSetPhoto(data: UserSetPhotoRequest, cookie?: string) {
    return await post<UserSetPhotoResponse>('/user/photo', data, { cookie })
  }

  public async userUpdateName(data: UpdateUserNameRequest, cookie?: string) {
    return await post<UpdateUserNameResponse>('/user/name', data, { cookie })
  }

  public async likeStore(data: { storeId: string }, cookie?: string) {
    return post('/store/favorite', data, { cookie })
  }

  public async unlikeStore(data: { storeId: string }, cookie?: string) {
    return post('/store/unfavorite', data, { cookie })
  }

  public async deleteMe(data: unknown, cookie?: string) {
    return post('/user/remove', {}, { cookie })
  }

  public async getPurchasedCompanyGiftCards(cookie?: string) {
    return get<{
      giftCards: PurchasedCompanyGiftCards[]
      joeGiftCards: PurchasedJoeGiftCard[]
    }>('/company-gift-card/history', {
      cookie,
    })
  }

  public async getCompanyInfo(data: { companyId: string }, cookie?: string) {
    return get<{
      name: string
      logo?: string
      giftCardImage: string | null
      companySlug: string | null
    }>(`/store/company-info?companyId=${data.companyId}`, { cookie })
  }

  public async getCompanyGiftCardById(
    { giftCardId }: GetCompanyGiftCardByIdRequest,
    cookie?: string
  ) {
    return get<GetCompanyGiftCardByIdResponse>('/company-gift-card/details', {
      params: { giftCardId },
      cookie,
    })
  }

  public async purchaseCompanyGiftCard(
    data: PurchaseCompanyGiftCardData,
    cookie?: string
  ) {
    return post<PurchaseCompanyGiftCardResponse>(
      '/company-gift-card/purchase',
      data,
      { cookie }
    )
  }

  public async activateCompanyGiftCard(
    data: ActivateCompanyGiftCardRequest,
    cookie?: string
  ) {
    return post('/company-gift-card/activate', data, { cookie })
  }

  public async getGiftCardOrders(
    { companyId }: { companyId?: string },
    cookie?: string
  ) {
    return get<Order[]>(
      companyId
        ? `/company-gift-card/orders?companyId=${companyId}`
        : '/company-gift-card/orders',
      { cookie }
    )
  }

  public async claimChallengesRewards(
    data: { challengeId: string },
    cookie?: string
  ) {
    return post('/user/challenges/claim', data, { cookie })
  }

  public async saveCart(data: { cart: CartData | null }, cookie?: string) {
    return post('/user/save-cart', data, { cookie })
  }

  public async getCart(cookie?: string) {
    return get<{ cart_data: CartData | null }>('/user/cart', { cookie })
  }

  public async getCartPageData(data: { storeId: string }, cookie?: string) {
    return get<{ showConfirmationModal: boolean }>(
      `/cart/data?storeId=${data.storeId}`,
      { cookie }
    )
  }

  public async getPin(cookie?: string) {
    return get<{ pin: string | null }>('/user/pin', { cookie })
  }

  public async getUserData(cookie?: string) {
    return get<
      Omit<
        ConsumerVerifyResponse,
        'nonAppOrderId' | 'isAppUserWithOrders' | 'cart'
      >
    >('/user/info', { cookie })
  }

  public async getJourneyStep(cookie?: string) {
    return get<{ journeyStep: string }>('/user/journey-step', { cookie })
  }

  public async getUserSettings(cookie?: string) {
    return get<UserSettingsResponse>('/user/settings', { cookie })
  }

  public async updateAutoReload(
    args: UpdateAutoReloadRequest,
    cookie?: string
  ) {
    return post('/user/settings/autoReload', args, { cookie })
  }

  public async getUserTransactionHistory(cookie?: string) {
    return get<GetUserTransactionHistoryResponse>('/user/transaction-history', {
      cookie,
    })
  }

  public async getFailedPayments(cookie?: string) {
    return get<GetFailedPaymentResponse>('/payment/failed-payments', {
      cookie,
    })
  }

  public async updateFailedPayments(
    data: UpdateFailedPaymentsRequest,
    cookie?: string
  ) {
    return post('/payment/update-failed-payments', data, { cookie })
  }

  public async addUsualItem(data: AddUsualItemRequest, cookie?: string) {
    return post<{ newUsualItemId: string }>('/item/add-usual-item', data, {
      cookie,
    })
  }

  public async removeUsualItem(data: RemoveUsualItemRequest, cookie?: string) {
    return post('/item/delete-usual-item', data, { cookie })
  }

  public async getUserInbox(
    { take = 5, skip = 0 }: GetUserInboxRequest = {},
    cookie?: string
  ) {
    return get<GetUserInboxResponse>('/inbox/list', {
      cookie,
      params: { take, skip },
    })
  }

  public async markUserInboxAsRead(
    data: { notificationId: string },
    cookie?: string
  ) {
    return post('/inbox/mark-as-seen', data, { cookie })
  }

  public async removeUserInbox(
    data: { notificationId: string },
    cookie?: string
  ) {
    return post('/inbox/remove', data, { cookie })
  }

  public async getCompanyGiftCardUploadHistory(
    { companyId }: GetCompanyGiftCardUploadsRequest,
    cookie?: string
  ) {
    const search = new URLSearchParams()
    if (companyId) search.set('companyId', companyId)

    const url = `/company-gift-card/uploads?${search.toString()}`

    return get<CompanyGiftCardPurchase[]>(url, { cookie })
  }

  public async deactivateCompanyGiftCard(
    { companyId }: { companyId: string },
    cookie?: string
  ) {
    return post('/company-gift-card/deactivate', { companyId }, { cookie })
  }

  public async getCompanyGiftCardDeals(
    { companyId }: { companyId: string },
    cookie?: string
  ) {
    return get<GetCompanyGiftCardDealsResponse>(
      `/gift-card/deals?companyId=${companyId}`,
      {
        cookie,
      }
    )
  }
}

export const apiClient = new DefaultApi()

export async function api<T extends keyof ArgsMap>(
  method: T,
  args?: ArgsMap[T],
  cookie?: string
): Promise<AsyncReturnType<DefaultApi[T]> & ApiStatus> {
  const response = (await apiClient[method](args as any, cookie)) as any
  const { data: apiData } = response

  response.success = apiData.success
  response.errors = apiData.errors

  response.data = isUndefined(apiData.data) ? apiData : apiData.data
  return response
}
