import { LocalStorageKeys } from 'config/localStorageKeys'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'

import { useLocalStorage } from './useLocalStorage'
import { useRouter } from 'next/router'

export type ChannelPayload = {
  data: Record<string, unknown>
  event: 'geo-request' | 'payment' | 'ping' | 'share'
}

const shopUrl = process.env.NEXT_PUBLIC_SHOP_URL

const useChannel = () => {
  const [eventPayload, setEventPayload] = useState('')
  const [shared, setShared] = useState(false)
  const [, setSessionCookie] = useCookies(['session'])
  const router = useRouter()

  const [nativeGeolocation, setNativeGeolocation] =
    useState<GeolocationPosition | null>(null)

  const [, setRequestReviewed] = useLocalStorage(
    LocalStorageKeys.REQUEST_REVIEWED,
    'false'
  )

  const { channel, isReactNative, isFlutter } = useMemo(() => {
    if (typeof window === 'undefined') {
      return { channel: null, isFlutter: false, isReactNative: false }
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const flutterChannel: Pick<Window, 'postMessage'> = window.WebViewChannel

    if (flutterChannel) {
      return { channel: flutterChannel, isFlutter: true, isReactNative: false }
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const reactNativeChannel: Pick<Window, 'postMessage'> = window.ReactNativeWebView

    if (reactNativeChannel) {
      return {
        channel: reactNativeChannel,
        isFlutter: false,
        isReactNative: true,
      }
    }

    return { channel: window.parent, isFlutter: false, isReactNative: false }
  }, [])

  useEffect(() => {
    if (channel) {
      const channelListener = (e: CustomEvent) => {
        setEventPayload(e.detail)
      }

      const geoRequestListener = (e: CustomEvent) => {
        setNativeGeolocation(e.detail)
      }

      const shareListener = (e: CustomEvent) => {
        /**
         * We cannot tell whether share was performed or canceled;
         * response is always:
         *
         * { success: true }
         */
        setShared(e.detail.success)
      }

      const reviewRequestListener = () => {
        setRequestReviewed('true')
      }

      const setSessionListener = (e: CustomEvent) => {
        setSessionCookie('session', e.detail.session)
      }

      const navigateListener = (
        e: CustomEvent<{ pathname: string, replace: boolean }>
      ) => {
        const { pathname, replace } = e.detail

        if (replace) {
          router.replace({ pathname })
        } else {
          router.push({ pathname })
        }
      }

      window.addEventListener(
        'geo-request',
        geoRequestListener as EventListener
      )

      window.addEventListener('ping', channelListener as EventListener)
      window.addEventListener('share', shareListener as EventListener)

      window.addEventListener(
        'review-request',
        reviewRequestListener as EventListener
      )

      window.addEventListener('session', setSessionListener as EventListener)

      window.addEventListener('navigate', navigateListener as EventListener)

      return () => {
        window.removeEventListener(
          'geo-request',
          geoRequestListener as EventListener
        )

        window.removeEventListener('ping', channelListener as EventListener)
        window.removeEventListener('share', shareListener as EventListener)

        window.removeEventListener(
          'review-request',
          reviewRequestListener as EventListener
        )

        window.removeEventListener(
          'session',
          setSessionListener as EventListener
        )

        window.removeEventListener(
          'navigate',
          navigateListener as EventListener
        )
      }
    }
  })

  const sendPayload = useCallback(
    (payload: unknown) => {
      if (!channel) {
        return
      }

      if (isFlutter || isReactNative) {
        channel.postMessage(payload)
        return
      }

      channel.postMessage(payload, shopUrl as string)
    },
    [channel, isFlutter, isReactNative]
  )

  const transmitGeoRequest = useCallback(() => {
    const payload = JSON.stringify({
      detail: {},
      event: 'geo-request',
    })

    sendPayload(payload)
  }, [sendPayload])

  const transmitReviewRequest = useCallback(() => {
    const payload = JSON.stringify({
      detail: {},
      event: 'review-request',
    })

    sendPayload(payload)
  }, [sendPayload])

  const transmitPayment = useCallback(
    (
      data: { amount: number },
      onReceivePaymentMethodId: (paymentMethodId: string) => void
    ) => {
      if (channel) {
        const paymentListener = (e: CustomEvent) => {
          onReceivePaymentMethodId(e.detail.paymentMethodId)
        }

        window.addEventListener('payment', paymentListener as EventListener, {
          once: true,
        })
      }

      const payload = JSON.stringify({
        detail: {
          amount: data.amount,
        },
        event: 'payment',
      })

      sendPayload(payload)
    },
    [channel, sendPayload]
  )

  const transmitPing = useCallback(() => {
    const payload = JSON.stringify({
      detail: {
        foo: 'bar',
      },
      event: 'ping',
    })

    sendPayload(payload)
  }, [sendPayload])

  const transmitShare = useCallback(
    (data: Record<string, unknown>) => {
      const payload = JSON.stringify({
        detail: {
          ...data,
        },
        event: 'share',
      })

      sendPayload(payload)
    },
    [sendPayload]
  )

  const transmitUser = useCallback(
    (data: { phone: string, userId: string }) => {
      const payload = JSON.stringify({
        detail: {
          ...data,
        },
        event: 'user',
      })

      sendPayload(payload)
    },
    [sendPayload]
  )

  const transmitPushNotificationRequest = useCallback(() => {
    const payload = JSON.stringify({
      detail: {},
      event: 'push-notification-request',
    })

    sendPayload(payload)
  }, [sendPayload])

  const transmitRouteChange = useCallback(
    (data: { route: string }) => {
      const payload = JSON.stringify({
        detail: {
          ...data,
        },
        event: 'route-change',
      })

      sendPayload(payload)
    },
    [sendPayload]
  )

  const transmitSession = useCallback(
    (detail: { session: string }) => {
      const payload = JSON.stringify({
        detail,
        event: 'session',
      })

      sendPayload(payload)
    },
    [sendPayload]
  )

  const transmitLogout = useCallback(() => {
    const payload = JSON.stringify({
      detail: {},
      event: 'logout',
    })

    sendPayload(payload)
  }, [sendPayload])

  const transmitExternalLink = useCallback(
    (url: string) => {
      const payload = JSON.stringify({
        detail: { url },
        event: 'external-link',
      })

      sendPayload(payload)
    },
    [sendPayload]
  )

  const transmitExternalMapLink = useCallback(
    (
      coordinates:
        | { lat: string, lng: string, name?: string }
        | { address: string, name?: string }
    ) => {
      const payload = JSON.stringify({
        detail: coordinates,
        event: 'external-map-link',
      })

      sendPayload(payload)
    },
    [sendPayload]
  )

  return {
    eventPayload,
    nativeGeolocation,
    shared,
    transmitGeoRequest,
    transmitPayment,
    transmitPing,
    transmitShare,
    transmitUser,
    transmitReviewRequest,
    transmitPushNotificationRequest,
    transmitRouteChange,
    transmitSession,
    transmitLogout,
    transmitExternalLink,
    transmitExternalMapLink,
  }
}

export default useChannel
