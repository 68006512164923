import useChannel from 'hooks/useChannel'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'

export const useNativeChannel = () => {
  const router = useRouter()
  const { transmitRouteChange, transmitSession } = useChannel()
  const [cookie, setCookie] = useCookies(['session'])

  useEffect(() => {
    const handleRouteChange = (route: string) => {
      transmitRouteChange({ route })
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router, transmitRouteChange])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.onMessageFromRN = (message: string) => {
      const { eventName, detail } = JSON.parse(message)
      const rnEvent = new CustomEvent(eventName, { detail })

      window.dispatchEvent(rnEvent)
    }
  }, [])

  useEffect(() => {
    const { session } = cookie

    if (session) {
      transmitSession({ session })
    }
  }, [cookie, transmitSession])

  useEffect(() => {
    const { session, ...currentParams } = router.query

    if (session) {
      setCookie('session', session, {
        path: '/',
        domain: '.joe.coffee',
      })

      router.replace(
        {
          pathname: router.pathname,
          query: currentParams,
        },
        undefined
      )
    }
  }, [cookie, setCookie, router])
}
